import React, { useEffect } from "react";
import { ExtendedResponseChatModel, HotelCard, initMessages, Message, ResponseChatModel } from "../models/ChatModel";
import { getCookie } from "../utils/utils";

const updateLastItem =
    <T>(msgFn: (message: T) => T) =>
        (currentMessages: T[]) =>
            currentMessages.map((msg, i) => {
                if (currentMessages.length - 1 === i) {
                    return msgFn(msg);
                }
                return msg;
            });

export const useChatCompletion = (clientId: string, url: URL) => {
    const [messages, setMessages] = React.useState<Message[]>(initMessages());
    const [loading, setLoading] = React.useState(false);
    const [error, setError] = React.useState<string | null>(null);

    function isChatResponse(obj: any): obj is ResponseChatModel {
        return obj && typeof obj === "object" && "text" in obj;
    }

    function isExtendedResponse(obj: any): obj is ExtendedResponseChatModel {
        return obj && typeof obj === "object" && ("message" in obj || "tours" in obj || "searchLink" in obj);
    }

    const handleHotelResponse = (hotels: HotelCard[], offerData?: any): HotelCard[] | null => {
        if (hotels.length === 0) return null;

        const hotelsWithOfferData = hotels.map((tour) => ({
            ...tour,
            offerData: {...offerData}
        }));

        const getPopularity = (hotelCard: HotelCard): number => {
            const {hotel} = hotelCard;

            if (hotelCard.offerData?.kids) {
                return hotel?.familyPopularity ?? hotel?.popularity ?? 0;
            }

            const adultsCount =
                typeof hotelCard.offerData?.adults === "string"
                    ? parseInt(hotelCard.offerData?.adults, 10)
                    : hotelCard.offerData?.adults;

            if (adultsCount ?? 1 > 1) {
                return hotel?.adultsPopularity ?? hotel?.popularity ?? 0;
            } else if (adultsCount === 1) {
                return hotel?.singleAdultPopularity ?? hotel?.popularity ?? 0;
            }

            return hotel?.popularity ?? 0;
        };

        return hotelsWithOfferData.sort((a, b) => getPopularity(b) - getPopularity(a));
    };

    function vibrate() {
        if ("vibrate" in navigator) {
            navigator.vibrate(1);
        }
    }

    const submitPrompt = React.useCallback(
        async (newMessage: Message) => {
            setLoading(true);
            setError(null);

            const updatedMessages = [
                ...messages,
                newMessage,
                {role: "assistant", content: ""} as Message,
            ];
            setMessages(updatedMessages);

            try {
                const response = await fetch(url, {
                    method: "POST",
                    headers: {
                        "Content-Type": "application/json",
                    },
                    body: JSON.stringify({
                        clientId,
                        input: newMessage.content,
                        clientIdFromOT: getCookie("o_client_id") ?? null,
                    }),
                });

                if (!response.ok) {
                    throw new Error("Network response was not ok");
                }

                const reader = response.body?.getReader();
                const decoder = new TextDecoder();
                let buffer = "";
                let hotelsReceived = false;

                if (reader) {
                    while (true) {
                        const {done, value} = await reader.read();
                        if (done) break;

                        const chunk = decoder.decode(value, {stream: true});
                        buffer += chunk;

                        // Process complete SSE events
                        const events = buffer.split(/\n\n/);
                        buffer = events.pop() || ""; // Keep the last incomplete event in the buffer

                        for (const event of events) {
                            const lines = event.split("\n");
                            const dataLines = lines
                                .filter((line) => line.startsWith("data:"))
                                .map((line) => line.slice(5).trim());

                            if (dataLines.length === 0) continue;

                            const jsonStr = dataLines.join("");

                            try {
                                const result = JSON.parse(jsonStr);

                                if (isExtendedResponse(result)) {
                                    if (result.tours && !hotelsReceived) {
                                        hotelsReceived = true;
                                        const sortedHotels = handleHotelResponse(result.tours, result.offerData);
                                        if (sortedHotels) {
                                            setMessages(
                                                updateLastItem<Message>((msg) => ({
                                                    ...msg,
                                                    id: result.id,
                                                    content: msg.content ? `${msg.content}${result.message || ""}` : (result.message || ""),
                                                    hotels: sortedHotels,
                                                }))
                                            );
                                        }
                                    }

                                    if (result.message) {
                                        setMessages(
                                            updateLastItem<Message>((msg) => ({
                                                ...msg,
                                                id: result.id,
                                                content: msg.content ? `${msg.content}${result.message || ""}` : (result.message || ""),
                                                buttonLink: (result.tours && result.tours?.length > 0) ? undefined : result.searchLink,
                                            }))
                                        );
                                    }
                                    vibrate();
                                } else if (isChatResponse(result)) {
                                    setMessages(
                                        updateLastItem<Message>((msg) => ({
                                            ...msg,
                                            id: result.id,
                                            content: `${msg.content}${result.text}`,
                                        }))
                                    );
                                    vibrate();
                                }

                                if (result.isEnd) break;
                            } catch (e) {
                                console.error("Failed to parse JSON:", e);
                            }
                        }
                    }
                }
            } catch (err) {
                console.log(err);

                setError(
                    err instanceof Error
                        ? err.message
                        : "An unknown error occurred"
                );

                setMessages(
                    updateLastItem((msg) => ({
                        content: `${msg.content}\n К сожалению, произошла непредвиденная ошибка. Пожалуйста, попробуйте ещё раз или повторите попытку позже.`,
                        role: "assistant",
                    }))
                );
            } finally {
                setLoading(false);
            }
        },
        [clientId, messages, url]
    );

    useEffect(() => {
        const savedMessages = localStorage.getItem(`${clientId}_m`);
        try {
            if (savedMessages) {
                setMessages(JSON.parse(savedMessages));
            }
        } catch (err) {}
    }, [clientId]);

    useEffect(() => {
        if (messages.length > 3 && !loading) {
            localStorage.setItem(`${clientId}_m`, JSON.stringify(messages));
        }
    }, [clientId, messages, loading]);

    return {
        messages,
        loading,
        error,
        submitPrompt,
        setMessages,
    };
};
