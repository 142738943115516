import React, { useRef, useState, useCallback, useEffect } from "react";
import { BottomSheet, BottomSheetRef } from "react-spring-bottom-sheet";
import "react-spring-bottom-sheet/dist/style.css";
import { useVirtualKeyboard } from "../../hooks/useVirtualKeyboard";
import { ReactComponent as Expand } from "../../images/expand.svg";
import { ReactComponent as Collapse } from "../../images/collapce.svg";
import { SpringEvent } from "react-spring-bottom-sheet/dist/types";

interface ChatExpandableProps {
    isOpen: boolean;
    onClose: () => void;
    children: React.ReactNode;
    input: React.ReactNode;
    openFeedback: () => void;
    feedbackOpened: boolean;
}

const ChatExpandable: React.FC<ChatExpandableProps> = ({
    isOpen,
    onClose,
    children,
    input,
    openFeedback,
    feedbackOpened,
}) => {
    const [isExpanded, setIsExpanded] = useState(true);
    const isKeyboardOpen = useVirtualKeyboard();
    const cardRef = useRef<HTMLDivElement>(null);
    const sheetRef = useRef<BottomSheetRef>(null);

    const snapPoints = useCallback(
        ({ maxHeight }: { maxHeight: number }) => {
            if (isKeyboardOpen) {
                return [maxHeight - 50];
            }
            return [maxHeight - 50, maxHeight * 0.6];
        },
        [isKeyboardOpen]
    );

    const switchSize = () => {
        setIsExpanded((prev) => !prev);
        if (sheetRef.current) {
            if (!isExpanded) {
                sheetRef.current.snapTo(({ snapPoints }) => snapPoints[0]);
            } else {
                sheetRef.current.snapTo(({ snapPoints }) => snapPoints[1]);
            }
        }
    };

    const handleClose = () => {
        onClose();
    };

    const handleSpring = (e: SpringEvent) => {
        if (e.type === "SNAP" && e.source === "dragging") {
            handleExpand();
        }
    };

    const handleExpand = () => {
        if (
            sheetRef.current &&
            sheetRef.current.height > window.innerHeight - 55
        ) {
            setIsExpanded(true);
        } else setIsExpanded(false);
    };

    useEffect(() => {
        handleExpand();
    }, [isKeyboardOpen]);

    return (
        <BottomSheet
            open={isOpen}
            onDismiss={handleClose}
            snapPoints={snapPoints}
            defaultSnap={({ snapPoints }) => snapPoints[0]}
            blocking={true}
            scrollLocking={false}
            onSpringEnd={handleSpring}
            ref={sheetRef}
            header={
                <>
                    <div className="relative min-h-[20px] border-box cursor-ns-resize">
                        <div className="w-[40px] h-[6px] bg-neutral-400 rounded-full mx-auto transition-all" />
                    </div>
                    <div
                        className="absolute flex flex-row right-0 top-0 size-10 mt-4 z-50"
                        ref={cardRef}
                        onClick={switchSize}
                    >
                        <Expand
                            className="absolute mx-auto"
                            style={{
                                visibility: !isExpanded ? "visible" : "hidden",
                                opacity: !isExpanded ? "1" : "0",
                                transition:
                                    "visibility 0s, opacity 0.5s linear",
                            }}
                        />
                        <Collapse
                            className="absolute mx-auto"
                            style={{
                                visibility: isExpanded ? "visible" : "hidden",
                                opacity: isExpanded ? "1" : "0",
                                transition:
                                    "visibility 0s, opacity 0.5s linear",
                            }}
                        />
                    </div>
                </>
            }
            sibling={
                <>
                    {feedbackOpened ? (
                        <div
                            onClick={() => {
                                openFeedback();
                                handleClose();
                            }}
                            style={{
                                zIndex: 45,
                            }}
                            className={`fixed size-8 top-0 right-0 m-3 xl:right-[32dvw] xl:top-[calc(100dvh-60px)] rounded-full pointer-events-auto`}
                        ></div>
                    ) : (
                        ""
                    )}
                </>
            }
            children={children}
            footer={input}
            style={{ zIndex: 45, position: "fixed" }}
        />
    );
};

export default ChatExpandable;
