import React, { useState } from "react";
import {OfferData} from "../../models/ChatModel";
import axios from "axios";

interface HotelLinkProps {
    clientId: string;
    tourId?: string;
    url: string;
    backLink?: string;
    customText?: string;
    offerData?: OfferData;
}

const HotelLink: React.FC<HotelLinkProps> = ({
    clientId,
    tourId,
    url,
    backLink,
    customText,
                                                 offerData,
}) => {
    const [pressed, setPressed] = useState(false);

    const handleButtonClick = async () => {
        setPressed(true);
        // fix open link in safari
        const windowReference = window.open();

        try {
            const params = new URLSearchParams({
                clientId: clientId,
                offerId: tourId ?? "",
            });

            const response = await axios.post(`${url}api/v1/offer-link?${params}`, offerData);

            if (windowReference) {
                windowReference.location = response.data;
            }
        } catch (error) {
            console.error("Error:", error);
            if (windowReference) {
                windowReference.location = backLink ?? "https://www.onlinetours.ru";
            }
        } finally {
            setPressed(false);
        }
    };

    return (
        <div
            className={`mt-2 bg-yellow-400 xl:bg-[#FFB534] text-black py-2 px-4 rounded-xl w-full cursor-pointer ${
                pressed ? "animate-pulse cursor-wait" : ""
            }`}
        >
            <div
                className="text-center font-semibold xl:font-medium xl:text-xs xl:leading-none 2xl:text-base"
                onClick={handleButtonClick}
            >
                {customText ?? "Посмотреть варианты"}
            </div>
        </div>
    );
};

export default HotelLink;
