import { ArrowUpIcon } from "@radix-ui/react-icons";

interface InputInChatProps {
    handleSubmit: (e?: React.FormEvent) => void;
    cantSend: () => boolean;
    input: string;
    setInput: (input: string) => void;
    desktopRes: boolean;
    shouldFocus: boolean;
}

const InputInChat: React.FC<InputInChatProps> = ({
    handleSubmit,
    cantSend,
    input,
    setInput,
    desktopRes,
    shouldFocus,
}) => {
    const handleonKeyDown = (
        e: React.KeyboardEvent<HTMLTextAreaElement>
    ): boolean => {
        if (e.key === "Enter") {
            e.preventDefault();
            if (!cantSend()) {
                handleSubmit();
            }
        }
        return false;
    };

    const handleChange = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
        setInput(e.target.value);
    };

    return (
        <div
            onSubmit={handleSubmit}
            className="flex items-center min-h-[48px] max-h-[72px] xl:bottom-0"
        >
            <textarea
                name="chat"
                rows={input.length < 35 || desktopRes ? 1 : 2}
                value={input}
                autoFocus={shouldFocus && desktopRes}
                onFocus={(e) => {
                    e.preventDefault();
                    e.stopPropagation();
                    e.target.focus({ preventScroll: true });
                }}
                onChange={handleChange}
                onKeyDown={(e) => handleonKeyDown(e)}  
                className="flex-grow border rounded-2xl px-4 py-2 relative pr-16 resize-none outline-none overflow-hidden"
                placeholder="Введите сообщение"
            />
            <button
                disabled={cantSend()}
                style={{
                    backgroundColor: cantSend()
                        ? "rgba(0, 0, 51, 0.06)"
                        : "#5B66F7",
                }}
                onClick={handleSubmit}
                className={`absolute text-white p-2 rounded-lg right-6 size-8 transition-all xl:right-10 ${cantSend() ? "cursor-not-allowed" : ""}`}
            >
                <ArrowUpIcon
                    className="size-4 mx-auto"
                    color={cantSend() ? "rgba(0, 8, 48, 0.27)" : "white"}
                />
            </button>
        </div>
    );
};

export default InputInChat;
