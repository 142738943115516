import React from "react";

interface BouncingBubblesLoadingProps {
    loading: boolean;
}

const BouncingBubblesLoading: React.FC<BouncingBubblesLoadingProps> = ({
    loading,
}) => {
    return (
        <div
            className={`load-bounce ml-2 transition-all duration-150 ${
                loading ? "opacity-100" : "opacity-0"
            }`}
        >
            <div className="line-bounce"></div>
            <div className="line-bounce"></div>
            <div className="line-bounce"></div>
        </div>
    );
};

export default BouncingBubblesLoading;
