import React, { useEffect, useState } from "react";
import MarkdownText from "../common/MarkdownText";
import { Message, MessageReaction } from "../../models/ChatModel";
import HotelCardsCarousel from "../hotel/HotelCardsCarousel";
import { ReactComponent as Thumb } from "../../images/thumb.svg";
import { ReactComponent as ThumbPressed } from "../../images/thumb_pressed.svg";
import BouncingBubblesLoading from "../common/BouncingBubblesLoading";
import HotelCardsSmartCarousel from "../hotel/HotelCardsSmartCarousel";
import HotelLink from "../hotel/HotelLink";

interface ChatContentProps {
    desktopRes: boolean;
    messages: Message[];
    clientId: string;
    loading: boolean;
    handleFeedback: (feedback: MessageReaction, msg: Message) => void;
    messagesEndRef: React.MutableRefObject<null | HTMLDivElement>;
}

const ChatContent: React.FC<ChatContentProps> = ({
    desktopRes,
    messages,
    clientId,
    loading,
    handleFeedback,
    messagesEndRef,
}) => {
    const [openedCard, setOpenedCard] = useState<number>();

    const handleExpand = (index: number) => {
        setOpenedCard(index);
    };

    const isCollapsed = (index: number) => {
        console.log(index)
        console.log(openedCard)
        return index !== openedCard;
    }

    useEffect(() => {
        // hack b'cause findLastIndex unavailable in react-scripts env
        const latestCardIndex = [...messages].reverse().findIndex((m) => m.hotels && m.hotels.length > 0);
        const adjustedIndex = latestCardIndex === -1 ? -1 : messages.length - 1 - latestCardIndex;

        if (adjustedIndex !== openedCard) {
            setOpenedCard(adjustedIndex);
        }
    }, [messages]);

    const formatText = (text: string): string => text
        .replace(/\n/g, "\n\n")

    return (
        <div
            className={`flex-grow overflow-y-auto overflow-x-visible mb-4 pr-4 pt-12 ${desktopRes ? "overflow-hide" : ""
                }`}
            style={{
                overflowAnchor: "none",
            }}
        >
            {desktopRes && (
                <div className="fixed w-[32dvw] shadow-after top-[calc(0rem)] right-0 z-10" />
            )}
            {messages.map((msg, index) => (
                <div
                    key={index}
                    className={`mb-4 block rounded-lg ${msg.role === "user"
                        ? "text-right user-message"
                        : "text-left"
                        }`}
                >
                    {msg.role === "assistant" && msg.hotels ? (
                        <>
                            <MarkdownText>
                                {formatText(msg.content)}
                            </MarkdownText>
                            {!desktopRes && (
                                <HotelCardsCarousel
                                    clientId={clientId}
                                    cards={msg.hotels}
                                />
                            )}
                            {desktopRes && (
                                <HotelCardsSmartCarousel
                                    clientId={clientId}
                                    cards={msg.hotels}
                                    collapsed={isCollapsed(index)}
                                    onExpand={() => handleExpand(index)}
                                />
                            )}
                        </>
                    ) : (
                        <>
                            <div
                                className={`inline-block p-3 rounded-lg ${msg.role === "user"
                                    ? "bg-[#F1F1F1]"
                                    : "bg-transparent"
                                    }`}
                            >
                                {msg.role === "assistant" ? (
                                    <MarkdownText>
                                        {formatText(msg.content)}
                                    </MarkdownText>
                                ) : (msg.content)}
                            </div>
                            {msg.role === "assistant" && msg.buttonLink ? (
                                <HotelLink
                                    clientId={""}
                                    url={""}
                                    backLink={msg.buttonLink}
                                    customText="Открыть поиск"
                                />
                            ) : (
                                ""
                            )}
                        </>
                    )}
                    {msg.role !== "user" &&
                        index !== openedCard &&
                        (index !== messages.length - 1 || !loading) && (
                            <>
                                <br />
                                <button
                                    className="p-2"
                                    onClick={() =>
                                        handleFeedback("DISLIKE", msg)
                                    }
                                >
                                    {msg.feedback === "DISLIKE" ? (
                                        <ThumbPressed className="rotate-180 -scale-x-100 scale-y-100" />
                                    ) : (
                                        <Thumb className="rotate-180 -scale-x-100 scale-y-100" />
                                    )}
                                </button>
                                <button
                                    className="p-2"
                                    onClick={() => handleFeedback("LIKE", msg)}
                                >
                                    {msg.feedback === "LIKE" ? (
                                        <ThumbPressed fill="black" />
                                    ) : (
                                        <Thumb fill="black" />
                                    )}
                                </button>
                            </>
                        )}
                </div>
            ))}
            <BouncingBubblesLoading loading={loading} />
            <div
                className="h-[10dvh] xl:h-[15dvh] w-full"
                ref={messagesEndRef}
                style={{ float: "left", clear: "both", content: " " }}
            />
        </div>
    );
};

export default ChatContent;
