import { useState, useEffect } from "react";

export const useVirtualKeyboard = () => {
    const [isKeyboardOpen, setIsKeyboardOpen] = useState(false);

    useEffect(() => {
        if (typeof window !== "undefined" && window.visualViewport) {
            const initialViewportHeight = window.visualViewport.height;

            const handleResize = () => {
                if (window.visualViewport) {
                    const currentViewportHeight = window.visualViewport.height;

                    if (initialViewportHeight > currentViewportHeight) {
                        setIsKeyboardOpen(true);
                    } else {
                        setIsKeyboardOpen(false);
                    }
                }
            };

            window.visualViewport.addEventListener("resize", handleResize);

            return () => {
                if (window.visualViewport) {
                    window.visualViewport.removeEventListener(
                        "resize",
                        handleResize
                    );
                }
            };
        }
    }, []);

    return isKeyboardOpen;
};
