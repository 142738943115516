import React from "react";

interface InitialButtonsProps {
    onButtonClick: (text: string) => void;
    isChatOpen: boolean;
    started: boolean;
    desktopRes: boolean;
}

const PRESET_MESSAGES = [
    {
        text: "На море всей семьей",
        message: "Расскажите мне о семейном отдыхе на море.",
    },
    {
        text: "В романтическое путешествие",
        message: "Я хочу отправиться в романтическое путешествие.",
    },
    { text: "На поиски приключений", message: "На поиски приключений." },
    { text: "Мой вариант ...", message: "" },
];

const InitialButtons: React.FC<InitialButtonsProps> = ({
    onButtonClick,
    isChatOpen,
    started,
    desktopRes,
}) => {
    const Buttons = () => {
        return (
            <>
                {PRESET_MESSAGES.filter(
                    (m) => !desktopRes || (m.message !== "" && desktopRes)
                ).map((item, index) => (
                    <a
                        className="xl:mx-2 noselect"
                        style={{
                            visibility: !started ? "visible" : "hidden",
                            opacity: !started ? "1" : "0",
                            transition:
                                "visibility 0s linear 1s, opacity 0.5s linear 1s",
                        }}
                        href={"#chat"}
                        key={`${index}_im`}
                    >
                        <button
                            key={index}
                            className="w-fit px-4 py-2 mb-4 bg-[#5B66F7] rounded-xl transition-colors text-left"
                            onClick={() => onButtonClick(item.message)}
                        >
                            {item.text}
                        </button>
                    </a>
                ))}

                {!desktopRes && (
                    <a
                        href={"#chat"}
                        style={{
                            visibility: started ? "visible" : "hidden",
                            opacity: started ? "1" : "0",
                            transition:
                                "visibility 0s linear 1s, opacity 0.5s linear 1s",
                        }}
                    >
                        <button
                            className="w-fit px-4 py-2 mb-4 bg-[#5B66F7] rounded-xl transition-colors text-left noselect"
                            onClick={() => onButtonClick("")}
                        >
                            Вернуться к диалогу
                        </button>
                    </a>
                )}
            </>
        );
    };

    return (
        <>
            {!desktopRes || !started ? (
                <div
                    style={{ opacity: isChatOpen && !desktopRes ? 0 : 1 }}
                    className="flex flex-col p-6 h-screen w-screen xl:w-[69dvw] xl:inset-y-0 xl:left-0 xl:absolute bg-[#05001D] xl:rounded-r-3xl bg-opacity-45 transition-all duration-700 text-white xl:z-20"
                >
                    <h2 className="text-2xl xl:text-4xl xl:text-center font-bold mb-4 mt-16 xl:mt-56 sticky top-0 noselect">
                        Куда вы мечтаете отправиться?
                    </h2>
                    <p
                        className="text-sm mb-4 sticky top-0 xl:text-base xl:text-center xl:mb-10 noselect"
                        style={{
                            visibility: !started ? "visible" : "hidden",
                            opacity: !started ? "1" : "0",
                            transition:
                                "visibility 0s linear 1s, opacity 0.5s linear 1s",
                        }}
                    >
                        Выберите или напишите что-то свое
                    </p>
                    <div className="flex-grow xl:grow-0"></div>

                    {desktopRes ? (
                        <div className="flex justify-center">
                            <Buttons />
                        </div>
                    ) : (
                        <>
                            {" "}
                            <Buttons />
                        </>
                    )}
                </div>
            ) : (
                ""
            )}
        </>
    );
};

export default InitialButtons;
